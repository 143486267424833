/*  ===================================
    Mixins

    Place your mixins here.
    ===================================  */

@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin ghost-button($font: sans-serif, $font-size: 16px, $font-color: #333, $border-size: 2px, $border-color: #333, $padding: 8px 12px, $transition-speed: 0.3s, $hover-color: #888) {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font;
  font-size: $font-size;
  color: $font-color;
  border: $border-size solid $border-color;
  padding: $padding;
  -webkit-transition: color $transition-speed, background $transition-speed;
  transition: color $transition-speed, background $transition-speed;

  &:hover {
    background: $border-color;
    color: $hover-color;
  }
}

@mixin shadow() {
  $shadow-percentage: 0.24;
  box-shadow: 0px 3px 6px rgba(black, $shadow-percentage);
}
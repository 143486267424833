/*  ===================================
    Typography

    Place your global typography styles
    here.
    ===================================  */

/** ======================================
 * Default font stack.
 * Delete the font files from the `fonts`
 * folder if you are not using them.
 ======================================== */
// @import "fonts/open-sans";
// @import "fonts/droid-serif";

// font stacks
$sans-serif: "Barlow", "Open Sans", "Helvetica Neue", Helvetica, Arial,
  sans-serif;
$serif: "Droid Serif", "Georgia", Cambria, Times New Roman, Times, serif;

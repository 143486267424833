/*  ===================================
    Base

    This is where your base styles are
    for your mobile-first design
    ===================================  */
* {
  box-sizing: border-box;
}

body {
  font-family: $sans-serif;
  font-weight: $font-regular;
  font-size: 16px;
  display: flex;
}

#root {
  width: 100%;
}

.App {
  width: 100%;
  margin: 0 auto;
}

#wrapper.no-scroll {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

div.clear {
  clear: both;
}

.t-mar-10 {
  margin-top: 10px;
}

.t-mar-20 {
  margin-top: 20px;
}

.t-mar-30 {
  margin-top: 30px;
}

.t-mar-40 {
  margin-top: 40px;
}

.t-mar-50 {
  margin-top: 50px;
}

.t-mar-60 {
  margin-top: 60px;
}

.t-mar-70 {
  margin-top: 70px;
}

.t-mar-80 {
  margin-top: 80px;
}

.t-mar-90 {
  margin-top: 90px;
}

.t-mar-100 {
  margin-top: 100px;
}

.b-mar-10 {
  margin-bottom: 10px;
}

.b-mar-20 {
  margin-bottom: 20px;
}

.b-mar-30 {
  margin-bottom: 30px;
}

.b-mar-40 {
  margin-bottom: 40px;
}

.b-mar-50 {
  margin-bottom: 50px;
}

.b-mar-60 {
  margin-bottom: 60px;
}

.b-mar-70 {
  margin-bottom: 70px;
}

.b-mar-80 {
  margin-bottom: 80px;
}

.b-mar-90 {
  margin-bottom: 90px;
}

.b-mar-100 {
  margin-bottom: 100px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $sans-serif;
  font-weight: $font-extrabold;
}

p {
  font-weight: $font-regular;
  text-align: left;
}

button.disabled,
button:disabled {
  opacity: 0.7;
  pointer-events: none;
  filter: saturate(0.4);
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.fa-button {
  background-color: $apm-black;
  color: $apm-beige;
  height: 44px;
  line-height: 44px;
  padding: 0 30px;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  font-weight: $font-medium;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  cursor: pointer;
  transition: opacity 0s ease-in-out;

  &:hover {
    transition: opacity 0.4s ease-in-out;
    opacity: 0.9;
  }

  .svg-inline--fa {
    margin-left: 8px;
  }
}

/* Fading in page content */
.fade-in {
  opacity: 0;
}

.fade-in.visible {
  transition: opacity 1s ease-in;
  opacity: 1;
}

/* beige card */
.card {
  min-width: 320px;
  background-color: $apm-white;
  margin-bottom: 40px;
  margin: 0 $card-mobile-side-mar 40px;
  padding: 0 $card-mobile-side-pad;
  border-radius: 2px;
  overflow: hidden;
  @include shadow();
  border: 1px solid rgba(black, 0.12);
}

#wrapper {
  width: 100%;
  position: absolute;
  top: 0;

  .page-loader-wrapper {
    background-color: $apm-black;
    /* for testing */
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .graphics-wrapper {
      text-align: center;

      &>* {
        margin: 0 auto;
      }

      .text {
        color: $apm-blue-med;
        font-size: 12px;
        margin: 8px auto;
        padding-left: 1rem;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }
}

main {
  /* Main content area for page */
  width: 100%;
  // max-width: 375px;
  padding: 0 0 100px;
  // border: 1px solid rgba(goldenrod, 0.8);
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  // display: block !important;

  .circle-houses {
    position: absolute;
    $size: 350px;
    width: $size;
    height: $size;
    top: 320px;
    right: -($size/2);
    z-index: -100;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

/* Menu Popup */
.icon-button {
  width: 64px;
  height: 64px;
  margin-top: 14px;
  border: none;
  background-color: transparent;
  outline: none;
  color: $apm-beige;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 18px;
  }
}

#mobile-menu-b {
  position: absolute;
  top: 0;
  right: 0;
  color: $apm-black;
  z-index: 5000;

  &.open {
    color: $apm-beige;
  }
}

#popup-wrapper {
  height: 100vh;
  width: 100%;
  background-color: $apm-black;
  color: $apm-beige;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 4000;

  &.open {
    display: block;
  }

  #menu-close-b {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9000;
  }

  #popup-nav {
    position: relative;
    top: 140px;
    line-height: 4rem;

    a {
      padding-bottom: 8px;
    }

    a.active {
      border-bottom: 4px solid $apm-beige;
    }
  }

  a {
    font-weight: $font-thin;
    text-decoration: none;
    color: $apm-beige;
    font-size: 30px;
  }

  button {
    display: none;
  }
}

header {
  position: absolute;
  z-index: 5000;
  width: 100%;
  overflow-x: hidden; // prevent horizontal over-scrolling on mobile
  height: $header-height-mobile;

  #header-logo-wrapper {
    position: relative;
  }

  #header-nav-wrapper {
    display: none; // Hidden on mobile by default
  }

  #header-curve-desktop-end-block {
    display: none;
    position: absolute;
    height: 91px;
    top: 0;
    left: 0;
    background-color: $apm-black;
    width: 200px;
    width: calc(((100% - (100% - 80px)) / 2) + 10);
  }

  #header-curve-desktop {
    display: none;
    z-index: 0;
    width: 500px;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  /* curve graphic for mobile */
  #header-curve-mobile {
    position: absolute;
    top: 0;
    left: 0;
    height: 88px;
    width: 375px;
    pointer-events: none;
  }

  #mobile-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 100%;
    z-index: 0;
    background-color: $apm-black;
  }

  #top-logo {
    position: absolute;
    top: 28px;
    left: 30px;
    height: 30px;
    width: auto;
  }

  // #top-nav {}
}

.circle-bg-wrapper {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
}

/** ===================================
 * Pages
 ===================================== */

/* HOME page - base */
#home-hero-wrapper {
  width: 100%;
  position: relative;
  min-height: 467px;
  margin-bottom: 80px;

  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.desktop {
      display: none;
    }
  }

  #home-hero-action1-wrapper,
  #home-hero-action2-wrapper {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 120px;
  }

  #home-hero-action1-wrapper {
    padding: 0 40px;

    .home-hero-headline {
      width: 100%;
      font-size: 18px;
      font-weight: $font-extrabold;

      h1 {
        width: 100%;
      }
    }
  }

  #home-hero-action2-wrapper {
    display: none;
  }

  .masked-element {
    mask-image: url(../../images/home-hero-curve-mobile.png);
    mask-mode: alpha;
    mask-size: 100% 100%;
  }

  @media screen and (min-width: 960px) {
    height: 70vh;

    .masked-element {
      mask-image: url(../../images/home-hero-curve-desktop.png);
      mask-mode: alpha;
      mask-size: 100% 100%;
    }

    .hero-image.desktop {
      display: block;
    }

    .hero-image.mobile {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    #home-hero-action1-wrapper {
      padding: 0 80px;

      .home-hero-headline {
        h1 {
          font-size: 80px;
          width: 870px;
        }
      }

      .fa-button {
        height: 60px;
        font-size: 20px;
        padding: 0 45px;
      }
    }
  }
}

$home-breakpoint: 640px;

#home-main-wrapper {
  position: relative;
  // overflow-x: hidden;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 50px;

  @media screen and (min-width: $home-breakpoint) {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
}

#home-main-forms-wrapper {
  text-align: center;
  margin-top: 40px;

  .home-main-forms-header {
    font-weight: $font-bold;
    font-size: 24px;
  }

  button {
    margin-top: 30px;

    .svg-inline--fa {
      height: 16px;
      font-size: 16px;
    }
  }

  @media screen and (min-width: 800px) {
    .home-main-forms-header {
      font-size: 32px;
    }
  }
}

.home-main-block-wrapper {
  width: 100%;

  .main-content-block-wrapper {
    height: 375px;
    position: relative;

    @media screen and (min-width: $home-breakpoint) {
      height: 600px;
    }

    .main-block-image {
      width: 100%;
      height: 275px;
      box-shadow: 0 3px 5px rgba(black, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      @media screen and (min-width: $home-breakpoint) {
        height: 540px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .main-content-block {
      position: absolute;
      z-index: 100;
      bottom: 0;
      left: -20px;
      margin: 0;
      width: 240px;
      min-width: 120px;
      padding-left: 40px;
      padding-right: 20px;

      h2 {
        font-size: 18px;
        font-weight: $font-extrabold;
        color: $apm-blue-dark;
      }

      p {
        font-weight: $font-thin;
        font-size: 14px;
        line-height: 1.2rem;
      }

      @media screen and (min-width: 640px) {
        padding-bottom: 20px;
        width: 280px;

        h2 {
          font-size: 22px;
        }

        p {
          font-size: 14px;
          line-height: 1.7rem;
        }
      }

      @media screen and (min-width: 960px) {
        width: 70%;

        h2 {
          font-size: 34px;
        }

        p {
          font-size: 18px;
          line-height: 1.9rem;
        }
      }
    }
  }

  .home-hero-button {
    margin: 0 auto;
  }
}

@media screen and (min-width: $home-breakpoint) {
  #home-main-block-1 {
    .main-content-block-wrapper {
      .main-content-block {
        top: -40px;
        bottom: auto;
      }
    }
  }
}

#home-main-block-2 {
  .main-content-block-wrapper {
    .main-content-block {
      left: auto;
      right: -20px;
      padding-right: 40px;
      padding-left: 20px;
    }
  }
}

.circle-bg-wrapper {
  #houses-home-1 {
    $size: 350px;
    width: $size;
    top: 280px;
    left: auto;
    right: -($size/3);

    @media screen and (min-width: 960px) {
      top: 400px;
    }
  }

  #rings-home-1 {
    $size: 450px;
    width: $size;
    top: 800px;
    right: auto;
    left: -($size/3);
  }

  #rings-home-2 {
    $size: 400px;
    width: $size;
    top: 1400px;
    left: auto;
    right: -($size/3);
  }

  @media screen and (min-width: 800px) {
    #houses-home-1 {
      $size: 500px;
      width: $size;
      height: $size;
      right: -($size/6);
    }

    #rings-home-1 {
      $size: 450px;
      height: $size;
      width: $size;
      top: 800px;
      right: auto;
      left: -($size/5);
    }

    #rings-home-2 {
      $size: 400px;
      width: $size;
      top: auto;
      bottom: -($size/2);
      right: -($size/3);
    }
  }

  @media screen and (min-width: 1200px) {
    #houses-home-1 {
      $size: 600px;
      width: $size;
      height: $size;
      right: -($size/6);
    }

    #rings-home-2 {
      $size: 600px;
      width: $size;
      top: auto;
      bottom: -($size/3);
      right: -($size/3);
    }
  }
}

/* HOME page - base - end */

/* LISTINGS page - base */
#main-listings-content-wrapper {
  text-align: center;

  #listing-load-more-b {
    margin: 0 auto;
    width: 315px;
    font-weight: $font-medium;
  }
}

/* LISTINGS page - base - end */

/* ABOUT US page - base */
#main-about-content-wrapper {
  #description-content-block-wrapper {
    line-height: 1.4rem;
    padding: 0 20px 20px;

    p {
      color: $apm-gray-med;
    }
  }

  #main-contact-wrapper {
    #contact-header {
      font-weight: $font-extrabold;
      font-size: 18px;
      color: $apm-blue-dark;
      text-align: center;
    }

    #contact-instructions {
      font-size: 16px;
      line-height: 1.4rem;
      font-family: $font-regular;
      color: $apm-gray-med;
      margin-bottom: 30px;
    }
  }

  form {
    label {
      margin-top: 20px;
    }

    .button-wrapper {
      text-align: center;
      padding: 40px 0 60px;
    }

    button {
      @extend .fa-button;
      background-color: $apm-blue-dark;
      color: $apm-white;
      padding-left: 50px;
      padding-right: 50px;
    }
  }



  #pay-rent-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    form button {
      margin-top: 30px;
    }
  }
}

.circle-bg-wrapper {
  opacity: 0.3;

  #rings-about-1 {
    $size: 400px;
    width: $size;
    top: 500px;
    left: auto;
    right: -($size/2);
  }

  #rings-about-2 {
    $size: 600px;
    width: $size;
    top: 1200px;
    left: -($size/2);
  }
}

/* ABOUT US page - base - end */

/* DOWNLOAD FORMS page - base */
#main-dl-content-wrapper {
  color: $apm-gray-dark;
  display: flex;
  align-items: center;
  justify-content: center;

  .rings-wrapper {
    top: 80%;
  }

  #main-dl-content-block-wrapper {
    margin: 0 auto;
    padding: 40px 20px;
    max-width: 940px;
    width: 100%;

    @media screen and (min-width: 480px) {
      margin: 0 20px;
    }
  }

  #sendto-content-block-wrapper,
  #sendto-content-block-address {
    text-align: center;
    padding: 20px 0;
    font-weight: $font-medium;
    margin: 0 auto;

    p {
      text-align: center;
    }

    a {
      font-weight: $font-medium;
      color: $apm-blue-med-2;
      text-decoration: none;
    }
  }

  #main-forms-wrapper {
    @media screen and (min-width: 680px) {
      min-width: 346px;
      // max-width: 640px;
      background-color: white;
      margin-bottom: 40px;
      margin: 0 $card-mobile-side-mar 40px;
      padding: 40px $card-mobile-side-pad;
      // border-radius: 2px;
      overflow: hidden;
    }
  }

  .forms-item.long {
    display: grid;
    grid-template-columns: 250px 80px;

    @media screen and (min-width: 610px) {
      display: inline-block;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 20px;
  }

  .icons {
    display: inline-block;

    .download-file-link {
      padding: 10px;
    }

    svg {
      margin-left: 4px;
    }
  }

  label span {
    font-weight: $font-medium;
  }
}

.circle-bg-wrapper {
  #rings-download-1 {
    top: 680px;
    left: auto;
    $size: 540px;
    width: $size;
    height: $size;
    right: -($size/2);
  }
}

/* DOWNLOAD FORMS page - base - end */

/* WORK WITH US page - base */
#main-work-with-content-wrapper {
  #description-content-block-wrapper {
    line-height: 1.4rem;
    padding: 0 20px 20px;

    p {
      color: $apm-gray-med;
    }
  }

  #main-wrapper {
    padding: 0;
    max-width: 640px;
    margin: 0 auto;

    img {
      width: 100%;
    }

    h2 {
      padding: 16px 16px 0;
      text-align: center;
      font-weight: 800;
      font-size: 1.6rem;
      line-height: 2.0rem;
      color: $apm-blue-dark;
    }

    .copy {
      padding: 40px;
      margin: 20px auto;

      a {
        font-weight: $font-bold;
        text-decoration: none;
        color: $apm-blue-dark;
      }
    }
  }

  .form-wrapper {
    padding: 0 16px;
  }

  form {
    label {
      margin-top: 20px;
    }

    @media screen and (min-width: 768px) {
      .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
      }
    }

    .button-wrapper {
      text-align: center;
      padding: 40px 0 60px;
    }

    button {
      @extend .fa-button;
      background-color: $apm-blue-dark;
      color: $apm-white;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .bottom-button-wrapper {
    padding: 80px 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-size: 1.1rem;

      svg {
        transform: scale(1.2);
        position: relative;
        bottom: 0.15rem;
      }
    }
  }
}

/* WORK WITH US page - base - end */

/** Pages end           ============== */

/** ===================================
 * Components
 ===================================== */

/* PageHero */
.hero-wrapper {
  position: relative;
  height: 240px;
  max-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (min-width: 641px) {
    height: 600px;
  }

  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h1 {
    position: relative;
    z-index: 1000;
    font-size: 36px;
    padding-top: 80px;
  }

  @media screen and (min-width: 641px) {
    h1 {
      font-size: 60px;
    }
  }
}

#main-listings-content-block-wrapper {
  min-height: 480px;
}

/* ListingCard */
.listing-card-wrapper {
  padding: 0 0 30px;
  text-align: left;
  position: relative;

  .magnifying-glass {
    display: inline-block;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
  }

  .listing-attributes-wrapper {
    padding: 20px 25px;
    font-size: 14px;
  }

  .listing-attribute-item {
    $lh: 34px;
    height: $lh;
    display: grid;
    grid-template-columns: 24px 1fr;

    .line-item {
      display: inline-block;
      line-height: $lh;
    }

    .svg-inline--fa {
      width: 16px;
      display: inline-block;
      line-height: $lh;
      vertical-align: -0.58rem;
      margin-right: 5px;
    }

    &.public-transportation {
      .label {
        line-height: 1.4rem;
        margin-top: 4px;
      }
    }
  }

  .address {
    font-weight: $font-bold;
    display: inline-block;
    line-height: 1.2rem;
    padding-bottom: 20px;
  }

  .rent label {
    font-weight: $font-bold;
  }

  .deposit label {
    font-weight: $font-bold;
  }

  .listing-card-image {
    width: 100%;
    height: 250px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .optional {
    background: rgba($apm-blue-med, 0.1);
    padding: 10px 20px;
    margin-top: 12px;
    border: 1px solid rgba($apm-blue-med, 0.3);
    border-radius: 2px;

    &.padded {
      /* extra padding for public transportation */
      padding-bottom: 30px;
    }
  }

  .listing-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-size: 15px;
      font-weight: $font-medium;
      width: 188px;
      @include shadow();

      .svg-inline--fa {
        height: 20px;
        width: 15px;
      }
    }
  }
}

.circle-bg-wrapper {
  #rings-listings-1 {
    $size: 400px;
    width: $size;
    top: 400px;
    left: auto;
    right: -($size/2);
  }

  #rings-listings-2 {
    $size: 450px;
    width: $size;
    top: 800px;
    right: auto;
    left: -($size/2);
  }

  #rings-listings-3 {
    $size: 400px;
    width: $size;
    top: 1400px;
    left: auto;
    right: -($size/2);
  }

  @media screen and (min-width: 800px) {
    #rings-listings-1 {
      $size: 600px;
      width: $size;
      top: 400px;
      left: auto;
      right: -($size/2);
    }

    #rings-listings-2 {
      $size: 650px;
      width: $size;
      top: 800px;
      right: auto;
      left: -($size/2);
    }
  }
}

/* Form */
form {

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 6px auto 0;
    resize: vertical;
    display: block;
  }

  input[type="date"] {
    height: 44px;
  }

  input[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  select {
    height: 44px;
  }

  input[type="submit"]:hover {
    background-color: #45a049;
  }

  small.error {
    margin: 0 0 12px;
    color: $error-red;
    display: inline-block;
  }

  h1 {
    font-size: 24px;
    text-align: center;
  }

  .yes-no-wrapper {
    display: grid;
    grid-template-columns: 20px 50px 20px 1fr;
  }

  .form-group {
    margin-bottom: 30px;

    h3 {
      margin-top: 0;
      font-size: 14px;
      color: $apm-blue-dark;
    }

    h4 {
      font-size: 12px;
      color: $apm-blue-med-2;
    }

    label {
      font-size: 14px;
      color: $apm-blue-med;
      margin-top: 0px;
      display: block;
      font-weight: $font-regular;
    }
  }

  .form-nav-wrapper {
    padding: 20px 0 40px;
    margin-top: 20px;
    border-top: 1px solid $apm-beige;

    button {
      @extend .fa-button;
      background-color: $apm-blue-med;
      color: $apm-black;
      width: 45%;
      max-width: 250px;

      &:last-child {
        float: right;
      }
    }
  }
}

.paypal-form {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
}

/* ApplicationForm */
#application-wrapper {
  margin-top: 140px;

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  .property-address {
    text-align: center;
    margin: 0 auto 30px;
    font-weight: $font-medium;
    color: $apm-blue-med-2;
  }

  .bg-images-wrapper {
    position: absolute;
    overflow: hidden;
    top: 2.5%;
    left: 2.5%;
    width: 95%;
    height: 95%;
    z-index: -500;
    padding: 40px;

    img {
      position: absolute;
      width: 700px;
      display: none;
      @include shadow();

      &:first-child() {
        bottom: 400px;
      }

      &:last-child() {
        top: 200px;
        right: 0;
      }

      @media screen and (min-width: 940px) {
        display: block;
      }
    }
  }

  #progress-wrapper {
    width: 300px;
    height: 16px;
    margin: 0 auto 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;

    .bar {
      height: 3px;
      background-color: $apm-black;
      width: 240px;
      position: absolute;
      top: 7px;
      left: 30px;
      z-index: 0;
    }

    .circle {
      position: relative;
      z-index: 1000;
      background-color: $apm-beige;
      border: 2px solid $apm-black;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin: 0 auto;

      &.active {
        background-color: $apm-blue-med;
      }
    }
  }

  #address-temp,
  #address-perm {
    .row-group {
      display: grid;
      grid-template-columns: 70px 120px 1fr;
      grid-column-gap: 10px;
    }
  }

  #address-perm {
    margin-top: 0px;
  }

  #segment-1-misc-group {
    .row-group {
      display: grid;
      grid-template-columns: 1fr 90px;
      grid-column-gap: 10px;
    }
  }

  #acknowledgements {
    p {
      line-height: 1.5rem;

      u {
        font-weight: $font-medium;
      }
    }
  }

  .agreement-wrapper {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 10px;
  }

  #submission-complete-wrapper {
    padding: 40px 0;
    text-align: center;

    .message {
      padding: 0 50px;
      font-size: 22px;
      font-weight: $font-thin;
      line-height: 2.2rem;
      margin-bottom: 30px;
    }

    #return-to-listings-wrapper {
      margin-bottom: 30px;
    }
  }
}

.circle-bg-wrapper {
  #rings-application-1 {
    $size: 650px;
    width: $size;
    top: 100px;
    left: auto;
    right: -($size/3);
  }

  #rings-application-2 {
    $size: 650px;
    width: $size;
    top: 800px;
    right: auto;
    left: -($size/3);
  }

  #rings-application-3 {
    $size: 600px;
    width: $size;
    top: 1400px;
    left: auto;
    right: -($size/3);
  }
}

/* CircleRings */
.rings-wrapper {
  $size: 360px;
  width: $size;
  position: absolute;
  left: -($size/2);
  z-index: -1000;
}

/* Footer */
footer {
  background-color: $apm-black;
  color: $apm-beige;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 100px;

  a {
    text-decoration: none;
    color: $apm-beige;
    font-weight: $font-medium;

    &:visited {
      color: $apm-beige;
    }

    &:hover {
      color: rgba($apm-beige, 0.7);
    }

    &.active {
      color: rgba($apm-beige, 0.7);
    }
  }

  .right-block {
    position: relative;
  }

  .house {
    display: none;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;

    @media screen and (min-width: 968px) {
      display: block;
    }

    @media screen and (min-width: 1050px) {
      height: 60px;
      left: auto;
      right: 330px;
    }

    #window-lights {
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }

    &.lit #window-lights {
      opacity: 1;
      transition: opacity 1.6s ease-in-out;
    }
  }

  #bottom-nav {
    font-size: 16px;
    line-height: 3rem;
    text-align: center;
    padding-inline-start: 0;
    padding: 60px 0px 42px 0px;

    .fa-button {
      text-transform: capitalize;

      &:hover {
        color: rgba($apm-beige, 0.8) !important;
      }
    }
  }

  #contact-wrapper {
    .address {
      font-weight: $font-thin;
      font-size: 16px;
      margin-bottom: 4px;
    }

    #address1 {}

    #address2 {
      margin-bottom: 16px;
    }
  }

  #phone-number {
    margin-bottom: 16px;

    a {
      font-weight: $font-thin;
    }
  }

  #email-address {
    font-weight: $font-medium;
    margin-bottom: 40px;
  }

  .stay-connected {
    font-size: 16px;
    font-weight: $font-extrabold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .eho-logo {
    height: 60px;

    polygon,
    path {
      fill: $apm-beige;
    }
  }

  .mobile-social {
    margin: 20px auto 40px;

    a {
      padding: 15px;
    }
  }

  #aff-content-wrapper {
    margin-top: 30px;
  }

  .full-social {
    display: none;
  }

  .footer-small {
    font-size: 12px;
    line-height: 1.2rem;
    font-weight: $font-thin;
  }

  .made-with {
    margin-top: 14px;

    svg {
      margin: 0 2px;
      position: relative;
      top: 1px;
    }

    a {
      margin-left: 4px;
    }
  }
}

/* Footer end */

/* ImageGalleryModal */
#image-gallery-modal {
  z-index: 8000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.25);
  pointer-events: none;

  h1 {
    color: white;
  }
}

.ReactModalPortal {
  z-index: 9999;
}

/* ImageGalleryModal end */
/*  ===================================
    Variables

    Place your global variables here.
    ===================================  */

/* Brand Colors */
$apm-white: #fbf9fb;
$apm-beige: #dedac5;
$apm-blue-lt: #d6edf3;
$apm-blue-med: #8cb5d8;
$apm-blue-med-2: #5364a5;
$apm-blue-dark: #122755;
$apm-gray-dark: #4a4a4a;
$apm-gray-med: #4a4a4a;
$apm-black: #1d1d1d;

$card-mobile-side-mar: 14px;
$card-mobile-side-pad: 16px;

$error-red: #fa784c;

$font-thin: 100;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-extrabold: 800;

$header-height-mobile: 88px;
$header-height-desktop: 118px;
